<template>
	<div class="views-questions-answer" v-if="examsQuestion">
		<div class="answer-body M">
			<div class="answer-body-main">
				<div class="questions">
					<Estimate
						v-if="examsQuestion.questionType == 'judgment'"
						ref="Estimate"
						:key="examsQuestion.id"
						:examsQuestionListIndex="examsQuestionListIndex"
						:examsQuestion="examsQuestion"
						:details="details"
					></Estimate>
					<Operation
						v-if="examsQuestion.questionType == 'operation'"
						ref="Operation"
						:key="examsQuestion.id"
						:examsQuestionListIndex="examsQuestionListIndex"
						:examsQuestion="examsQuestion"
						:details="details"
					></Operation>
					<Discuss
						v-if="examsQuestion.questionType == 'discussion'"
						ref="Discuss"
						:key="examsQuestion.id"
						:examsQuestionListIndex="examsQuestionListIndex"
						:examsQuestion="examsQuestion"
						:details="details"
					></Discuss>
				</div>
				<div class="btns">
					<div class="previous-btn" @click="previous()">
						<el-button>上一题</el-button>
					</div>
					<div class="next-btn" @click="next()">
						<el-button type="custom-primary">下一题</el-button>
					</div>
				</div>
			</div>
			<div class="answer-body-side custom-scrollbar-y">
				<div class="scrollbar-content">
					<div class="answer-card">
						<div class="card-header">
							<div class="title">答题卡</div>
							<div class="right-box">
								<div class="yes">
									<div class="dot"></div>
									<div class="label">已答</div>
								</div>
								<div class="no">
									<div class="label">未答</div>
								</div>
								<div class="error">
									<div class="dot"></div>
									<div class="label">答错</div>
								</div>
							</div>
						</div>
						<div class="card-body">
							<div class="list">
								<div
									class="item"
									:class="{
										active: item.answerOption || item.answerContent,
										error: item.rightFlag == 0
									}"
									v-for="(item, index) in examsQuestionList"
									:key="item.id"
									@click="switchQuestion(index)"
								>
									<!-- <div class="item-content">{{ item.sequence < 9 ? `0${item.sequence}` : item.sequence }}</div> -->
									<div class="item-content">{{ index < 9 ? `0${index + 1}` : index + 1 }}</div>
								</div>
							</div>
						</div>
						<div class="card-footer">
							<div class="exit-btn" @click="exit()">
								<el-button>退出</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ExamsPaper } from '@/common/api.js';
import Estimate from '@/views/questions/analysis/components/Estimate.vue';
import Operation from '@/views/questions/analysis/components/Operation.vue';
import Discuss from '@/views/questions/analysis/components/Discuss.vue';
export default {
	components: {
		Estimate,
		Operation,
		Discuss
	},
	data() {
		return {
			examsQuestionList: [],
			examsQuestionListIndex: 0,
			details: {}
		};
	},
	computed: {
		examsQuestion() {
			let result = {};
			if (this.examsQuestionListIndex > -1) {
				result = this.examsQuestionList[this.examsQuestionListIndex];
			}
			return result;
		}
	},
	created() {
		document.documentElement.style.overflowY = 'auto';
		this.getExamsPaper();
	},
	beforeDestroy() {
		document.documentElement.style.overflowY = 'auto';
	},
	methods: {
		getExamsPaper() {
			ExamsPaper().then((res) => {
				this.details = res.data;
				if (!res.data.paperRecord.questionRecordList) {
					this.examsQuestionList = [];
				} else {
					this.examsQuestionList = res.data.paperRecord.questionRecordList;
				}
			});
		},
		exit() {
			this.navigateBack();
		},
		previous() {
			if (this.examsQuestionListIndex > 0) {
				this.examsQuestionListIndex--;
				this.$bus.$emit('backTop');
			}
		},
		next() {
			if (this.examsQuestionListIndex < this.examsQuestionList.length - 1) {
				this.examsQuestionListIndex++;
				this.$bus.$emit('backTop');
			}
		},
		switchQuestion(index) {
			this.examsQuestionListIndex = index;
		}
	}
};
</script>

<style lang="scss" scoped>
.views-questions-answer {
	flex: 1;
	display: flex;
	flex-direction: column;
	.answer-body {
		flex: 1;
		display: flex;
		.answer-body-main {
			min-width: 970px;
			display: flex;
			flex-direction: column;
			.questions {
				flex: 1;
				display: flex;
				flex-direction: column;
			}
			.btns {
				display: flex;
				margin: 60px 0;
				.previous-btn {
					margin-left: 322px;
					::v-deep {
						.el-button {
							padding: 0;
							display: flex;
							justify-content: center;
							align-items: center;
							width: 148px;
							height: 40px;
							border-radius: 4px 4px 4px 4px;
							font-family: PingFang SC, PingFang SC;
							font-weight: 500;
							font-size: 16px;
						}
					}
				}
				.next-btn {
					margin-left: 30px;
					::v-deep {
						.el-button {
							padding: 0;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 12px;
							width: 148px;
							height: 40px;
							border-radius: 4px 4px 4px 4px;
							font-family: PingFang SC, PingFang SC;
							font-weight: 500;
							font-size: 16px;
						}
					}
				}
			}
		}
		.answer-body-side {
			margin-left: 20px;
			display: flex;
			flex-direction: column;
			margin-top: 30px;
			position: sticky;
			top: calc($head-height + 30px);
			max-height: calc(100vh - $head-height - 30px);
			overflow-y: auto;
			width: 290px;
			.scrollbar-content {
				margin-right: 2px;
				display: flex;
				flex-direction: column;
				.answer-card {
					display: flex;
					flex-direction: column;
					// width: 290px;
					// height: 348px;
					background: #ffffff;
					box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
					border-radius: 4px 4px 4px 4px;
					border: 1px solid;
					border-image: linear-gradient(360deg, rgba(212, 222, 233, 1), rgba(255, 255, 255, 1)) 1 1;
					box-sizing: border-box;
					padding-top: 20px;
					padding-bottom: 20px;
					.card-header {
						padding: 0 20px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 20px;
						.title {
							font-family: PingFang SC, PingFang SC;
							font-weight: bold;
							font-size: 16px;
							color: #000000;
							line-height: 16px;
						}
						.right-box {
							display: flex;
							.yes {
								display: flex;
								align-items: center;
								margin-left: 30px;
								.dot {
									margin-right: 6px;
									width: 6px;
									height: 6px;
									background: #019a7f;
									border-radius: 50%;
								}
								.label {
									font-family: PingFang SC, PingFang SC;
									font-weight: 500;
									font-size: 12px;
									color: #666666;
									line-height: 12px;
								}
							}
							.error {
								display: flex;
								align-items: center;
								margin-left: 30px;
								.dot {
									margin-right: 6px;
									width: 6px;
									height: 6px;
									background: #f65959;
									border-radius: 50%;
								}
								.label {
									font-family: PingFang SC, PingFang SC;
									font-weight: 500;
									font-size: 12px;
									color: #666666;
									line-height: 12px;
								}
							}
							.no {
								display: flex;
								align-items: center;
								margin-left: 30px;
								.label {
									font-family: PingFang SC, PingFang SC;
									font-weight: 500;
									font-size: 12px;
									color: #666666;
									line-height: 12px;
								}
							}
						}
					}
					.card-body {
						flex: 1;
						.list {
							margin-top: -14px;
							margin-left: -14px;
							margin-bottom: 20px;
							display: flex;
							flex-wrap: wrap;
							padding-left: 20px;
							padding-right: 20px;
							box-sizing: border-box;
							.item {
								width: calc(100% / 6);
								box-sizing: border-box;
								padding-top: 14px;
								padding-left: 14px;
								&.active {
									.item-content {
										border-color: #019a7f;
										background-color: #019a7f;
										color: #ffffff;
									}
								}
								&.error {
									.item-content {
										border-color: #f65959;
										background-color: #f65959;
										color: #ffffff;
									}
								}
								.item-content {
									// width: 30px;
									height: 30px;
									background: #ffffff;
									border-radius: 4px 4px 4px 4px;
									border: 1px solid #ececec;
									box-sizing: border-box;
									display: flex;
									justify-content: center;
									align-items: center;
									font-family: PingFang SC, PingFang SC;
									font-weight: bold;
									font-size: 14px;
									color: #019a7f;
									line-height: 18px;
									cursor: pointer;
									// &:hover {
									// 	border-color: #019a7f;
									// }
								}
							}
						}
					}
					.card-footer {
						display: flex;
						flex-direction: column;
						box-sizing: border-box;
						padding: 0 20px;
						.exit-btn {
							::v-deep {
								.el-button {
									width: 100%;
									padding: 0;
									display: flex;
									justify-content: center;
									align-items: center;
									// width: 250px;
									height: 40px;
									border-radius: 4px 4px 4px 4px;
									font-family: PingFang SC, PingFang SC;
									font-weight: 500;
									font-size: 16px;
								}
							}
						}
					}
				}
			}
		}
	}
	.views-questions-answer-footer {
		.answer-footer-btns {
			display: flex;
			margin: 60px 0;
			.previous-btn {
				margin-left: 322px;
				::v-deep {
					.el-button {
						padding: 0;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 148px;
						height: 40px;
						border-radius: 4px 4px 4px 4px;
						font-family: PingFang SC, PingFang SC;
						font-weight: 500;
						font-size: 16px;
					}
				}
			}
			.next-btn {
				margin-left: 30px;
				::v-deep {
					.el-button {
						padding: 0;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 12px;
						width: 148px;
						height: 40px;
						border-radius: 4px 4px 4px 4px;
						font-family: PingFang SC, PingFang SC;
						font-weight: 500;
						font-size: 16px;
					}
				}
			}
		}
	}
}
</style>
