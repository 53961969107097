<template>
	<div class="operation-component">
		<div class="operation-body">
			<div class="card operation">
				<div class="card-header">
					<div class="type">{{ serial }}.碳核算操作题</div>
					<div class="remark">(共{{ details.questionNumber }}道题，合计{{ details.totalScores }}分)</div>
					<div class="tag" v-if="examsQuestion.questionLabel">
						<template v-if="examsQuestion.questionLabel == 'organize'">组织碳</template>
						<template v-if="examsQuestion.questionLabel == 'product'">产品碳</template>
					</div>
					<div class="score">(本题分数：{{ examsQuestion.totalScores }}分)</div>
				</div>
				<div class="card-main">
					<div class="question">
						<div class="question-title ql-editor" v-html="examsQuestion.examsQuestion.questionTitle"></div>
						<div
							class="question-tip ql-editor"
							v-html="examsQuestion.questionContent && formatRichText(examsQuestion.questionContent)"
							@click="imageChagange($event)"
						></div>
					</div>
					<div class="analysis">
						<div class="line"></div>
						<div class="answer-box">
							<div class="header">
								<div class="title">你的答案：</div>
								<div class="score-box">
									<div class="label">本题得分：</div>
									<div class="score">
										<div class="value">{{ examsQuestion.acquireScores }}</div>
										<div class="total">/{{ examsQuestion.totalScores }}</div>
									</div>
								</div>
							</div>
							<div class="body">
								<span class="ql-editor" v-html="examsQuestion.answerContent && formatRichText(examsQuestion.answerContent)" @click="imageChagange($event)"></span>
							</div>
						</div>
						<template v-if="examsQuestion.questionAnalysis">
							<div class="analysis-title">题目解析：</div>
							<div class="analysis-content">{{ examsQuestion.questionAnalysis }}</div>
						</template>
						<template v-if="examsQuestion.checkComment">
							<div class="analysis-title">专家评语：</div>
							<div class="analysis-content">{{ examsQuestion.checkComment }}</div>
						</template>
					</div>
				</div>
			</div>
		</div>

		<el-image-viewer v-if="showViewer" :z-index="999999" :initial-index="previewIndex" :appendToBody="false" :on-close="closeViewer" :url-list="srcListView" />
	</div>
</template>

<script>
export default {
	name: 'Operation', // 操作题
	components: {
		ElImageViewer: () => import('element-ui/packages/image/src/image-viewer')
	},
	props: {
		examsQuestionListIndex: {
			type: [Number, String],
			default: -1
		},
		examsQuestion: {
			type: [Object],
			default() {
				return {};
			}
		},
		details: {
			type: [Object],
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			showViewer: false,
			srcListView: [{}],
			previewIndex: 0
		};
	},
	computed: {
		serial() {
			return this.examsQuestionListIndex + 1;
		}
	},
	methods: {
		//富文本渲染文本点击
		imageChagange(e) {
			if (e.target.nodeName === 'IMG' || e.target.nodeName == 'img') {
				//判断是否图片标签
				const img = e.target.currentSrc; //获取点击图片地址
				this.onPreview([img]);
			}
		},
		//开启富文本图片预览
		onPreview(img) {
			this.srcListView = img;
			this.showViewer = true;
			document.documentElement.style.overflowY = 'hidden';
		},
		//关闭富文本渲染文本图片预览
		closeViewer() {
			this.showViewer = false;
			document.documentElement.style.overflowY = 'auto';
		}
	}
};
</script>

<style lang="scss" scoped>
.operation-component {
	flex: 1;
	display: flex;
	flex-direction: column;
	.operation-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		.card {
			flex: 1;
			display: flex;
			flex-direction: column;
			width: 970px;
			// height: 700px;
			background: #ffffff;
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
			border-radius: 4px 4px 4px 4px;
			border: 1px solid;
			border-image: linear-gradient(360deg, rgba(212, 222, 233, 1), rgba(255, 255, 255, 1)) 1 1;
			box-sizing: border-box;
			margin-top: 30px;
			padding-bottom: 30px;
			box-sizing: border-box;
			.card-header {
				// width: 970px;
				height: 62px;
				background: linear-gradient(90deg, #dcdcdc 0%, #c2c2c2 100%);
				border-radius: 4px 4px 0px 0px;
				box-sizing: border-box;
				padding: 0 30px;
				display: flex;
				.type {
					font-family: PingFang SC, PingFang SC;
					font-weight: bold;
					font-size: 22px;
					color: #111111;
					line-height: 62px;
				}
				.remark {
					margin-left: 10px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: #666666;
					line-height: 62px;
				}
				.score {
					margin-left: auto;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: #666666;
					line-height: 62px;
				}
				.tag {
					align-self: center;
					border-color: #019a7f;
					background-color: #019a7f;
					color: #ffffff;
					padding: 0 7px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 14px;
					line-height: 24px;
					height: 24px;
					border-radius: 5px;
					margin-left: 10px;
				}
			}
			.card-main {
				display: flex;
				flex-direction: column;
				.question {
					display: flex;
					flex-direction: column;
					.question-title {
						box-sizing: border-box;
						padding: 35px 30px 0;
						font-family: PingFang SC, PingFang SC;
						font-weight: bold;
						font-size: 20px;
						color: #000000;
						// line-height: 20px;
					}
					.question-tip {
						margin-top: 20px;
						box-sizing: border-box;
						padding: 0 30px;
						font-family: PingFang SC, PingFang SC;
						font-weight: 500;
						font-size: 16px;
						color: #163638;
						line-height: 32px;
					}
				}
				.analysis {
					display: flex;
					flex-direction: column;
					box-sizing: border-box;
					padding: 0 30px;
					.line {
						margin-top: 40px;
						height: 1px;
						background-color: #ededed;
					}
					.answer-box {
						display: flex;
						flex-direction: column;
						margin-top: 20px;
						.header {
							display: flex;
							justify-content: space-between;
							.title {
								font-family: PingFang SC, PingFang SC;
								font-weight: bold;
								font-size: 16px;
								color: #000000;
								line-height: 16px;
							}
							.score-box {
								display: flex;
								font-family: PingFang SC-Medium;
								font-weight: 500;
								font-size: 16px;
								color: #000000;
								line-height: 16px;
								.label {
								}
								.score {
									display: flex;
									align-items: baseline;
									.value {
										color: #019a7f;
										font-weight: Bold;
									}
									.total {
										font-size: 12px;
										color: #333;
										font-weight: Bold;
									}
								}
							}
						}
						.body {
							margin-top: 20px;
						}
					}
					.analysis-title {
						margin-top: 20px;
						font-family: PingFang SC, PingFang SC;
						font-weight: bold;
						font-size: 16px;
						color: #000000;
						line-height: 16px;
					}
					.analysis-content {
						margin-top: 20px;
						font-family: PingFang SC, PingFang SC;
						font-weight: 500;
						font-size: 16px;
						color: #019a7f;
						line-height: 32px;
					}
				}
			}
		}
	}
}
</style>
