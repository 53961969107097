<template>
	<div class="estimate-component">
		<div class="estimate-body">
			<div class="card estimate">
				<div class="card-header">
					<div class="type">{{ serial }}.判断题</div>
					<div class="remark">(共{{ details.questionNumber }}道题，合计{{ details.totalScores }}分)</div>
					<div class="tag" v-if="examsQuestion.questionLabel">
						<template v-if="examsQuestion.questionLabel == 'organize'">组织碳</template>
						<template v-if="examsQuestion.questionLabel == 'product'">产品碳</template>
					</div>
					<div class="score">(本题分数：{{ examsQuestion.totalScores }}分)</div>
				</div>
				<div class="card-main">
					<div class="question">
						<div class="question">
							<div class="question-title ql-editor" v-html="examsQuestion.examsQuestion.questionTitle"></div>
						</div>
						<div class="question-single">
							<el-checkbox-group v-model="value" disabled>
								<el-checkbox :label="item.id" v-for="(item, index) in examsQuestion.examsQuestionOptionList" :key="item.id">
									<span class="label">{{ index | estimateLetterFilter }}{{ `. ` }}{{ item.content }}</span>
								</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
					<div class="analysis">
						<div class="line"></div>
						<div class="answer-box">
							<div class="your-answer">
								<div class="label">你的答案：</div>
								<div class="value" v-if="examsQuestion.answerOption">
									{{ examsQuestion.examsQuestionOptionList.findIndex((v) => v.id == examsQuestion.answerOption) | estimateLetterFilter }}
								</div>
							</div>
							<div class="correct-answer">
								<div class="label">正确答案：</div>
								<div class="value">{{ examsQuestion.examsQuestionOptionList.findIndex((v) => v.rightFlag == 1) | estimateLetterFilter }}</div>
							</div>
							<div class="score-box">
								<div class="label">本题得分：</div>
								<div class="score">
									<div class="value">{{ examsQuestion.acquireScores }}</div>
									<div class="total">/{{ examsQuestion.totalScores }}</div>
								</div>
							</div>
						</div>
						<template v-if="examsQuestion.questionAnalysis">
							<div class="analysis-title">题目解析：</div>
							<div class="analysis-content">{{ examsQuestion.questionAnalysis }}</div>
						</template>
						<template v-if="examsQuestion.checkComment">
							<div class="analysis-title">专家评语：</div>
							<div class="analysis-content">{{ examsQuestion.checkComment }}</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Estimate', //判断题
	props: {
		examsQuestionListIndex: {
			type: [Number, String],
			default: -1
		},
		examsQuestion: {
			type: [Object],
			default() {
				return {};
			}
		},
		details: {
			type: [Object],
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			value: []
		};
	},
	computed: {
		serial() {
			return this.examsQuestionListIndex + 1;
		}
	},
	created() {
		this.value = this.examsQuestion.answerOption ? [this.examsQuestion.answerOption] : [];
	},
	methods: {
		handleChange(e) {
			if (e.length > 1) {
				this.value = [e[e.length - 1]];
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.estimate-component {
	flex: 1;
	display: flex;
	flex-direction: column;
	.estimate-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		.card {
			flex: 1;
			display: flex;
			flex-direction: column;
			width: 970px;
			// height: 700px;
			background: #ffffff;
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
			border-radius: 4px 4px 4px 4px;
			border: 1px solid;
			border-image: linear-gradient(360deg, rgba(212, 222, 233, 1), rgba(255, 255, 255, 1)) 1 1;
			box-sizing: border-box;
			margin-top: 30px;
			padding-bottom: 30px;
			box-sizing: border-box;
			.card-header {
				// width: 970px;
				height: 62px;
				background: linear-gradient(90deg, #dcdcdc 0%, #c2c2c2 100%);
				border-radius: 4px 4px 0px 0px;
				box-sizing: border-box;
				padding: 0 30px;
				display: flex;
				.type {
					font-family: PingFang SC, PingFang SC;
					font-weight: bold;
					font-size: 22px;
					color: #111111;
					line-height: 62px;
				}
				.remark {
					margin-left: 10px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: #666666;
					line-height: 62px;
				}
				.score {
					margin-left: auto;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 16px;
					color: #666666;
					line-height: 62px;
				}
				.tag {
					align-self: center;
					border-color: #019a7f;
					background-color: #019a7f;
					color: #ffffff;
					padding: 0 7px;
					font-family: PingFang SC, PingFang SC;
					font-weight: 500;
					font-size: 14px;
					line-height: 24px;
					height: 24px;
					border-radius: 5px;
					margin-left: 10px;
				}
			}
			.card-main {
				display: flex;
				flex-direction: column;
				.question {
					display: flex;
					flex-direction: column;
					.question-title {
						box-sizing: border-box;
						padding: 35px 30px 0;
						font-family: PingFang SC, PingFang SC;
						font-weight: bold;
						font-size: 20px;
						color: #000000;
						// line-height: 20px;
					}
					.question-single {
						box-sizing: border-box;
						padding-left: 30px;
						margin-top: 35px;
						::v-deep {
							.el-checkbox-group {
								display: flex;
								flex-direction: column;
								.el-checkbox {
									display: flex;
									align-items: center;
									&:not(:first-child) {
										margin-top: 40px;
									}
									.el-checkbox__input {
										display: flex;
										align-items: center;
										&.is-focus {
											.el-checkbox__inner {
												border-color: #dcdfe6;
											}
										}
										&.is-checked {
											.el-checkbox__inner {
												border-color: #008f50;
												background-color: transparent;
												&::after {
													background-color: #008f50;
												}
											}
										}
										.el-checkbox__inner {
											border-radius: 50%;
											width: 20px;
											height: 20px;
											display: flex;
											justify-content: center;
											align-items: center;
											// box-sizing: border-box;
											&:hover {
												border-color: #008f50;
											}
											&::after {
												box-sizing: border-box;
												content: '';
												height: 10px;
												left: initial;
												position: absolute;
												top: initial;
												width: 10px;
												border-radius: 50%;
												background-color: #dcdfe6;
												transform: none;
											}
										}
										.el-checkbox__original {
										}
									}
									.el-checkbox__label {
										font-family: PingFang SC-Medium;
										font-weight: 500;
										font-size: 16px;
										color: #000000;
										line-height: 16px;
									}
								}
							}
						}
					}
				}
				.analysis {
					display: flex;
					flex-direction: column;
					box-sizing: border-box;
					padding: 0 30px;
					.line {
						margin-top: 40px;
						height: 1px;
						background-color: #ededed;
					}
					.answer-box {
						display: flex;
						margin-top: 40px;
						.your-answer {
							display: flex;
							font-family: PingFang SC-Medium;
							font-weight: 500;
							font-size: 16px;
							color: #000000;
							line-height: 16px;
							margin-right: 40px;
							.label {
							}
							.value {
								font-weight: Bold;
								color: #019a7f;
							}
						}
						.correct-answer {
							display: flex;
							font-family: PingFang SC-Medium;
							font-weight: 500;
							font-size: 16px;
							color: #000000;
							line-height: 16px;
							margin-right: 40px;
							.label {
							}
							.value {
								font-weight: Bold;
								color: #019a7f;
							}
						}
						.score-box {
							display: flex;
							font-family: PingFang SC-Medium;
							font-weight: 500;
							font-size: 16px;
							color: #000000;
							line-height: 16px;
							margin-right: 40px;
							.label {
							}
							.score {
								display: flex;
								align-items: baseline;
								.value {
									color: #019a7f;
									font-weight: Bold;
								}
								.total {
									font-size: 12px;
									color: #333;
									font-weight: Bold;
								}
							}
						}
					}
					.analysis-title {
						margin-top: 40px;
						font-family: PingFang SC, PingFang SC;
						font-weight: bold;
						font-size: 16px;
						color: #000000;
						line-height: 16px;
					}
					.analysis-content {
						margin-top: 40px;
						font-family: PingFang SC, PingFang SC;
						font-weight: 500;
						font-size: 16px;
						color: #019a7f;
						line-height: 32px;
					}
				}
			}
		}
	}
}
</style>
